import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import style from "./region-list.mod.scss"
import Container from "../container/container"
import Navigator from "../global/navigator"

const RegionList = ({ regions }) => {
  return (
    <Container gutters pt={2} pb={2} width={"medium"}>
      <div className={style.list}>
        {regions.map((region) => (
          <Navigator recordId={region.id}>
            <Card region={region} />
          </Navigator>
        ))}
      </div>
    </Container>
  )
}

const Card = ({ region }) => (
  <div className={style.list__card}>
    <div className={style.list__card_image}>
      <GatsbyImage
        image={region.regionImage.gatsbyImageData}
        alt={region.regionImage.alt}
      />
    </div>
    <div className={style.list__card_content}>
      <h4>{region.title}</h4>
      <p>{region.description}</p>
    </div>
  </div>
)

export default RegionList
