import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/global/layout"
import Intro from "../components/collections/intro"
import RegionList from "../components/regions/region-list"

const Regions = ({ data: { regions, datoCmsRegionsPage } }) => {
  const breadcrumbs = [
    { title: datoCmsRegionsPage.title, id: datoCmsRegionsPage.id },
  ]

  return (
    <Layout seo={datoCmsRegionsPage.seoMetaTags}>
      <Intro
        breadcrumbs={breadcrumbs}
        title={datoCmsRegionsPage.title}
        description={datoCmsRegionsPage.description}
      />
      <RegionList regions={regions.nodes} />
    </Layout>
  )
}

export default Regions

export const query = graphql`
  {
    datoCmsRegionsPage {
      id: originalId
      title
      description
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    regions: allDatoCmsRegionParent(sort: {fields: position}) {
      nodes {
        title
        id: originalId
        description
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        regionImage {
          gatsbyImageData(
            layout: CONSTRAINED
            imgixParams: {
              h: "250"
              fit: "fill"
              fill: "solid"
              w: "280"
              q: 60
              fillColor: "00ffffff"
              auto: "format"
            }
          )
          alt
        }
      }
    }
  }
`
